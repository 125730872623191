"use client";
import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";
import React from "react";
import Spinner from "../../components/spinner";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import {Fade} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import styles from './index.module.css';
import Dispatcher from "../../utils/Dispatchers/Dispatcher";

// import {Poppins} from "next/font/google";
// const poppins = Poppins({
//     weight: '400',
//     subsets: ['latin'],
// })

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface FileUploadProps {
    maxImages: number;
    setFiles: any;
}


export const Slideshow = (props: { images: [{ url: string, caption: string }], plan?: string }) => {
    const maxImages = props?.plan == 'premium' ? 10 : 3
    return (
        <div style={{display: 'flex', maxWidth: 230, placeContent: 'center'}}>
            <Fade cssClass={styles.fade} easing={'ease-out'} autoplay={true} infinite={true} arrows={false}
                  transitionDuration={1000}
                  duration={4000}
                // duration={100}
            >
                {props.images.map((fadeImage, index) => (
                    ((!props.plan || index < maxImages) && (
                        <div className="each-slide" key={index}
                             style={{
                                 display: 'flex',
                                 placeContent: 'center',
                                 top: 0,
                                 minWidth: 230,
                             }}>
                                <img
                                    width={0}
                                    height={0}
                                    sizes="100vw"
                                    className={styles.slideContainer}
                                    style={{width: '50%', height: 'auto', borderRadius: 12}}
                                    loading={'eager'} alt={"Couple."} src={fadeImage.url}/>
                        </div>
                    ))))
                }
            </Fade>
        </div>
    )
}

export function FileUploader(props: FileUploadProps) {
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const maxImages = props.maxImages;
    const dispatcher = new Dispatcher();

    async function createFiles(files: FileList | null) {
        if (!files || files.length == 0)
            return
        let filesArr: { url: string; caption: string; }[] = [];
        const maxImages = files.length > props.maxImages ? props.maxImages : files.length;
        for (let i = 1; i <= maxImages; i++) {
            // const urlFile = URL.createObjectURL(files[i-1])
            // filesArr.push({url: urlFile, caption: 'Caption ' + i, s3_url: s3url})
            const s3url = await uploadFileToS3(files[i - 1])
            filesArr.push({url: s3url, caption: 'Caption ' + i})
        }
        props.setFiles(filesArr);
    }

    async function uploadFileToS3(file: any) {
        setLoading(true)
        return await dispatcher.uploadFile(file)
            .then((data) => {
                    if (!data)
                        return
                    return data;
                }
            ).finally(
                () => {
                    setLoading(false);
                    setLoaded(true);
                }
            );
    }

    async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        setLoading(true)
        const files: FileList | null = e?.target?.files;
        setLoaded(true)
        await createFiles(files);
        setLoading(false);
        // setFile(file);
        // await requestDispatcher.uploadFile(file)
        //     .then((data) => {
        //             if (!data)
        //                 return
        //             field.value = data;
        //         }
        //     ).finally(
        //         () => {
        //             setLoading(false);
        //             setLoaded(true);
        //         }
        //     );
    }

    function getUploaderIcon() {
        if (loading)
            return
        return !loaded ? <CloudUploadIcon style={{color: 'white'}}/> : <CloudDoneIcon style={{color: 'white'}}/>;
    }


    return (
        <Button
            component="label"
            role={undefined}
            fullWidth={true}
            style={{
                color: "white",
                backgroundColor: '#F51D53',
                borderColor: '#F51D53',
                padding: 10
            }}
            variant="outlined"
            tabIndex={-1}
            startIcon={getUploaderIcon()}
        >
            <>
                {loading && <Spinner></Spinner>}
                {!loading && !loaded &&
                    // <p className={poppins.className} style={{color: "white"}}>Choose {maxImages.toString()} photos</p>
                    <p style={{color: "white"}}>Choose {maxImages.toString()} photos</p>
                }
                <VisuallyHiddenInput
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    accept={"image/*"}
                />
            </>
        </Button>
    );
}