import React from "react";
import styles from "./index.module.css";
import {Footer} from "../../components/footer";
import Form from "../../components/form";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Create() {

    return (
        <section className={styles.page}>
            <main className={styles.main}>
                <div style={{paddingTop: '30px'}}>
                    <h1 className={styles.mainText}>You're <em style={{color: '#fb6f92', fontStyle: "normal"}}>almost
                        done!</em></h1>
                    <h1 className={styles.description}>Just enter your details to complete your page.</h1>
                </div>
                <Form></Form>
            </main>
            <Footer></Footer>
            <ToastContainer/>
        </section>
    );
}
