import Dispatcher from "../../../utils/Dispatchers/Dispatcher";

export default class GiftDispatcher extends Dispatcher {
    apiUrl: string = this.apiBaseUrl + 'gifts';

    async update(body: any, slug: string) {
        const url = this.apiUrl + '/' + slug + '/update/'
        return await this.axiosPost(url, body)
    }

    async create(body: any) {
        const url = this.apiUrl + '/new/'
        return await this.axiosPost(url, body)
    }

    async get(slug: string, params = {}) {
        const url = this.apiUrl + '/' + slug + '/'
        return await this.read(url, params)
    }

    async sendEditEmail(token: string) {
        const url = this.apiUrl + '/' + token + '/edit-email/'
        return await this.axiosPost(url, {})
    }

    async getByToken(token: string) {
        const url = this.apiUrl + '/token/' + token + '/'
        return await this.read(url, {})
    }
}