"use client";
import {Button} from "@mui/material";
import React from "react";
import styles from "./index.module.css"
import RedeemIcon from '@mui/icons-material/Redeem'
import { useNavigate } from "react-router-dom";

export function MainButton(props: {width?: number}) {
    const width = props.width ? props.width : '100%';
    const navigate = useNavigate();
    return (
        <Button variant="contained"
                size={'large'}
                sx={{backgroundColor: '#F51D53', height: 60, width: width, borderRadius: 25}}
                className={styles.mainButton}
                startIcon={<RedeemIcon/>}
                onClick={() => navigate('/create')}
        >
            <p>Create Gift</p>
        </Button>);
}