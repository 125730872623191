import axios, {AxiosError, AxiosResponse} from 'axios';

export default class Dispatcher {
    apiBaseUrl: string = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

    handleError(error: AxiosError<any>) {
        throw error;
    }

    handleSuccess(success: AxiosResponse<any>) {
        return success.data
    }

    async axiosPost(url: string, body: any, options: any = {}) {
        return await axios.post(url, body, options)
            .then((response: AxiosResponse) => {
                return this.handleSuccess(response);
            })
            .catch((error: any) => {
                return this.handleError(error);
            });
    }

    async read(url: string, params: any = {}) {
        return await axios.get(url, params)
            .then((response: AxiosResponse) => {
                return this.handleSuccess(response);
            })
            .catch((error: any) => {
                return this.handleError(error);
            });
    }

    private getHeaders(options: any = {}) {
        return {headers: {...options}};
    }

    async post(url: string, body?: any, headers: any = {}) {
        const options = this.getHeaders(headers);
        return await this.axiosPost(url, body, options);
    }

    async uploadFile(file: any) {
        let formData = new FormData();
        formData.append("file", file);
        const url = this.apiBaseUrl + 'upload/image/';
        return await this.post(url, formData, {
            'Content-Type': 'multipart/form-data'
        })
    }
}
