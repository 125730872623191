'use client';
import styles from "./index.module.css"
import React from "react";
// import Image from "next/image";


export function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer}>
                <div className={styles.footerSection}>
                    <div className={styles.aligment}>
                        <p>Copyright © 2025 - <strong>All rights reserved.</strong></p>
                        <p>Extreme Programming LTDA</p>
                        <p>CNPJ: 46.083.601/0001-06</p>
                    </div>
                </div>
                <div className={styles.footerSection}>
                    <strong><h3>Support</h3></strong>
                    <div className={styles.column}>
                        <a href="mailto: xptoolscenter@gmail.com?subject=MyLoverr Support"
                           className={styles.link}>xptoolscenter@gmail.com</a>
                    </div>
                </div>
                <div className={styles.footerSection}>
                    <strong>
                        <h3 style={{display: 'flex', gap: 6, alignItems: 'center'}}>
                            <img src={'./icon-250.png'} alt={'Logo'} width={25} height={25}/>
                            <a href={'/'}>MyLoverr</a>
                        </h3>
                    </strong>
                    <a onClick={() => window.location.replace("/#example")}
                       className={styles.link}>Example</a>
                    <a onClick={() => window.location.replace("/#howItWorks")} className={styles.link}>How it
                        Works?</a>
                    <a onClick={() => window.location.replace("/#prices")} className={styles.link}>Prices</a>
                </div>
            </div>
        </footer>);
}