import styles from "../create/index.module.css";
import Form from "../../components/form";
import {Footer} from "../../components/footer";
import {ToastContainer} from "react-toastify";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import GiftDispatcher from "../../utils/Dispatchers/GiftDispatcher";
import Spinner from "../../components/spinner";

export default function Edit() {
    const {token} = useParams();
    const dispatcher = new GiftDispatcher();
    const [loading, setLoading] = React.useState(true);

    const [gift, setGift] = React.useState({
        couple_slug: "",
        youtube_id: "",
        relationship_start_date: "",
        files: undefined,
        letter: "",
        email: "",
        token: ""
    });

    useEffect(() => {
        if (!token)
            return

        dispatcher.getByToken(token.toString()).then(
            data => {
                setGift(data)
            },
            err => {
                if (err.status === 404)
                    window.location.replace('/')
                    // setNotFound(true)
            }
        ).finally(() => setLoading(false));
    }, []);

    return (
        <section className={styles.page}>
            <main className={styles.main}>
                <div style={{paddingTop: '30px'}}>
                    <h1 className={styles.mainText}>Edit <em style={{color: '#fb6f92', fontStyle: "normal"}}>your
                        page!</em></h1>
                    <h1 className={styles.description}>Just enter your details to edit.</h1>
                </div>
                {!loading && gift && <Form token={gift.token} email={gift.email} files={gift.files} letter={gift.letter} songID={gift.youtube_id}
                       startDate={gift.relationship_start_date} coupleName={gift.couple_slug}></Form>}
                {loading && (
                    <div style={{display: 'flex', placeContent: 'center'}}>
                        <Spinner/>
                    </div>
                )}
            </main>
            <Footer></Footer>
            <ToastContainer/>
        </section>
    );
}