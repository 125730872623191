import React from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import CouplePage from "./pages/couple-page";
import {BrowserRouter} from 'react-router-dom';
import Create from "./pages/create/page";
import Edit from "./pages/edit/page";

import ReactGA from "react-ga4";

ReactGA.initialize("G-4G5GWVY7F8");

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:slug" element={<CouplePage/>}/>
                <Route path="create/" element={<Create/>}/>
                <Route path="edit/:token" element={<Edit/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
