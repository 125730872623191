import dayjs from "dayjs";
import React, {useEffect, useState} from "react";

export function Countdown(props: { startDate: Date }) {

    const [date, setDate] = useState(dayjs(props.startDate).toDate().getTime());
    const [now, setNow] = useState(dayjs().toDate().getTime())
    const [years, setYears] = useState(0)
    const [months, setMonths] = useState(0)
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setCountdown();
        }, 1000);
    }, [now])

    function setCountdown() {
        setDate(dayjs(props.startDate).toDate().getTime());
        setNow(dayjs().toDate().getTime());
        let timeTogether = dayjs(now - date).toDate().getTime();
        const aSecond = 1000;
        const aMinute = aSecond * 60;
        const aHour = aMinute * 60;
        const aDay = aHour * 24;
        const aMonth = aDay * 30;
        const aYear = aMonth * 12;
        const years = Math.trunc(timeTogether / aYear);
        setYears(years);
        timeTogether -= years * aYear;
        const months = Math.trunc(timeTogether / aMonth);
        timeTogether -= months * aMonth;
        setMonths(months);
        const days = Math.trunc(timeTogether / aDay);
        timeTogether -= days * aDay;
        setDays(days);
        const hours = Math.trunc(timeTogether / aHour);
        timeTogether -= hours * aHour;
        setHours(hours);
        const minutes = Math.trunc(timeTogether / aMinute);
        timeTogether -= minutes * aMinute;
        setMinutes(minutes);
        const seconds = Math.trunc(timeTogether / aSecond);
        setSeconds(seconds);
    }

    return (
        <span style={{
            textWrap: 'pretty',
            textAlign: 'center',
            fontSize: 14,
            color: "#fb6f92",
        }}>
            <strong>
            {years > 0 && `${years} year${years > 1 ? 's' : ''},  `}
            {months > 0 && `${months} month${months > 1 ? 's' : ''},  `}
            {days > 0 && `${days} day${days > 1 ? 's' : ''}`}
            <br/>
            {hours > 0 && `${hours} hour${hours > 1 ? 's' : ''},  `}
            {minutes > 0 && `${minutes} minute${minutes > 1 ? 's' : ''}  and  `}
            {seconds > 0 && `${seconds} second${seconds > 1 ? 's' : ''}`}
            </strong>
            <br/>
            <br/>
            <em style={{color: 'white', fontStyle: 'normal'}}>have passed since the start</em>
        </span>
    )
}